<template>
  <div id="services-home">
    <wave-component color="primary"/>

    <div class="main-container">
      <div id="services-bar" class="d-flex text-center white--text align-center justify-center pb-10 main-container">
        <div>
          <div class="link-box"/>
          <div id="telecom" :class="['btn-services', {active: carouselItem === 0}]" @click="carouselItem = 0">
            TELECOM
          </div>
        </div>
        <div>
          <h2 class="link-box service-title">Serviços</h2>
          <div id="dev" :class="['btn-services', {active: carouselItem === 1}]" @click="carouselItem = 1">
            DEV
          </div>
        </div>
        <div>
          <div class="link-box"/>
          <div id="automation" :class="['btn-services', {active: carouselItem === 2}]" @click="carouselItem = 2">
            AUTOMAÇÃO
          </div>
        </div>
      </div>

      <v-carousel v-model="carouselItem" height="auto"
                  :show-arrows="false"
                  hide-delimiter-background
                  hide-delimiters
                  touchless
      >
        <v-carousel-item class="service-carousel-item">
          <service-card/>
        </v-carousel-item>

        <v-carousel-item class="service-carousel-item">
          <service-card/>
        </v-carousel-item>

        <v-carousel-item class="service-carousel-item">
          <service-card/>
        </v-carousel-item>
      </v-carousel>
    </div>
  </div>
</template>

<script>
import WaveComponent from '@/components/WaveComponent.vue';
import ServiceCard from '@/views/home/ServiceCard.vue';

export default {
  name: 'ServicesHome',
  components: {ServiceCard, WaveComponent},
  data: () => ({
    carouselItem: 0,
  }),
  methods: {
    //
  }
}
</script>

<style scoped>
#services-home {
  background-color: #0d0c37;
  font-size: 25px;
}

#telecom {
  background-color: #fd6600;
}

#dev {
  background-color: #126095;
  margin-left: 25px;
  margin-right: 25px;
}

#automation {
  background-color: #42741b;
}

.btn-services {
  width: 200px;
  padding: 5px 5px;
  border-radius: 22px;
  opacity: 0.3;
  cursor: pointer;
}

.btn-services.active {
  opacity: 1;
}

.link-box {
  height: 70px;
}

.service-title {
  color: #E7E6FF;
  letter-spacing: 2px;
  font-size: 40px;
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 959px) {
  #dev {
    margin: auto;
  }

  #services-bar {
    font-size: 10px;
  }

  .service-title {
    font-size: 30px;
  }

  .btn-services {
    width: 100px;
    font-size: 14px;
  }
}
</style>