<template>
  <div>
    <div class="buttons-bar">
      <div :style="cssProps"
           :class="['button-bar', {active: carouselItem === i.id}]"
           v-for="i in services.slice(0, 4)" :key="i.id"
           @click="carouselItem = i.id"
      >
        {{ i.title }}
      </div>
    </div>

    <v-carousel v-model="carouselItem" touchless hide-delimiters hide-delimiter-background :show-arrows="false" height="auto">
      <v-carousel-item v-for="i in services" :key="i.id" :value="i.id">
        <v-container class="pa-5 fill-height service-box" fluid>
          <v-row class="fill-height" no-gutters>
            <v-col class="service-content-box" cols="12" md="6">
              <div class="display-title pb-6">
                <v-avatar class="my-5" width="auto" height="auto">
                  <v-img class="service-icon" :src="i.icon ? require(`@/assets/services/${i.icon}`) : require('@/assets/logo.png')"/>
                </v-avatar>

                <h3 class="headline service-title" :style="{'color': color}">{{ i.title }}</h3>
              </div>

              <p class="white--text service-text">
                {{ i.txt || '' }}
              </p>
            </v-col>
            <v-col class="d-flex service-image-box fill-height" cols="12" md="6">
              <img class="service-image" :src="i.img ? require(`@/assets/services/${i.img}`) : require('@/assets/logo.png')" alt="image"/>
            </v-col>
          </v-row>
        </v-container>
      </v-carousel-item>
    </v-carousel>

    <div class="buttons-bar">
      <div :style="cssProps"
           :class="['button-bar', {active: carouselItem === i.id}]"
           v-for="i in services.slice(4, 8)" :key="i.id"
           @click="carouselItem = i.id"
      >
        {{ i.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // props: {
  //   services: {
  //     type: Array,
  //     required: true
  //   }
  // },
  name: 'ServiceCard',
  data: () => ({
    carouselItem: 0,
    color: '#fd6600',
    services: [
      {
        id: 1,
        title: 'Projetos FTTX',
        icon: 'fibra.png',
        img: 'campo-tecnicos.png',
        txt: 'Nossos serviços de projetos FTTX oferecem soluções de conectividade de alta velocidade e confiabilidade para sua empresa ou comunidade. Desenvolvemos projetos personalizados para implantação de redes de fibra óptica até o ponto de acesso final (FTTX), permitindo uma infraestrutura escalável e de alto desempenho. Com expertise em planejamento, design e implementação, garantimos a entrega de soluções FTTX eficientes, que impulsionam a conectividade e promovem a excelência na transmissão de dados, voz e vídeo.'
      },
      {
        id: 2,
        title: 'Rede FTTH'
      },
      {
        id: 3,
        title: 'Compartilhamento de Infraestrutura'
      },
      {
        id: 4,
        title: 'Certificação de Rede Óptica'
      },
      {
        id: 5,
        title: 'Enlace de Rádio'
      },
      {
        id: 6,
        title: 'Mapeamento de Rede Óptica'
      },
      {
        id: 7,
        title: 'Infraestrutura de Intranet em UTP e FTTA'
      },
      {
        id: 8,
        title: 'Montagem de Novos Provedores'
      }
    ],
  }),
  methods: {
    //
  },
  computed: {
    cssProps() {
      return {
        '--buttons-bar-active': this.color,
      }
    }
  }
}
</script>

<style scoped>
.buttons-bar {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: center;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.buttons-bar::-webkit-scrollbar {
  display: none;
}

.button-bar.active {
  /*noinspection CssUnresolvedCustomProperty*/
  color: var(--buttons-bar-active);
  /*noinspection CssUnresolvedCustomProperty*/
  box-shadow: 1px 1px 5px 5px var(--buttons-bar-active);
}

.button-bar {
  flex-shrink: 0;
  font-size: 18px;
  border-radius: 20px;
  background-color: #222242;
  cursor: pointer;
  padding-left: 12px;
  padding-right: 12px;
  margin-left: 40px;
  margin-right: 40px;
  color: white;
  text-align: center;
  line-height: 22px;
  height: 50px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-icon {
  height: 80px;
  width: 80px;
}

.service-content-box {
  order: 1;
}

.service-image-box {
  order: 2;
}

.service-title {
  font-size: 30px !important;
}

.service-text {
  font-size: 18px;
  line-height: 30px;
}

.service-image {
  margin: auto;
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

.service-box {
  border-radius: 10px;
  border: 5px solid #222241;
  height: 591px;
  overflow-y: scroll;

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.service-box::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 959px) {
  .service-box{
    height: auto;
  }

  .service-icon {
    margin-right: 10px;
    height: 50px;
    width: 50px;
  }

  .service-title {
    font-size: 25px !important;
  }

  .service-text {
    font-size: 14px;
  }

  .service-content-box {
    order: 2;
  }

  .service-image-box {
    order: 1;
  }

  .display-title {
    display: flex;
    align-items: center;
    padding-bottom: 0 !important;
  }

  .button-bar {
    height: 25px;
    width: 125px;
    font-size: 12px;
    line-height: 12px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 690px) {
  .buttons-bar {
    justify-content: left;
  }
}
</style>