<template>
  <v-app v-show="show">
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  provide() {
    return {}
  },
  data: () => ({
    show: false
  }),
  mounted() {
    this.$nextTick(() => {
      const animation = document.getElementById('logo-container');
      if (animation) {
        animation.remove();
      }
      this.show = true;
    });
  },
  methods: {}
}
</script>

<style>
.main-container {
  max-width: 1920px !important;
  margin: auto;
  padding-left: 140px !important;
  padding-right: 140px !important;
}

@media screen and (max-width: 1060px) {
  .main-container {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
}

@media screen and (max-width: 959px) {
  .main-container {
    padding-left: 8% !important;
    padding-right: 8% !important;
  }
}
</style>