<template>
  <v-container id="main-home" class="pa-y primary main-container" fluid>
    <v-row class="fill-height main-row" align="center" no-gutters>
      <v-col class="main-home-text" cols="12" lg="7">
        <div class="pb-5">
          Do planejamento à execução, <br>
          conectamos sua empresa com a inovação.
          Construindo o futuro da <span class="main-home-text-blue">automação, desenvolvimento e telecomunicações.</span>
        </div>

        <div>
          <a href="#" target="_blank" class="main-home-text-link">
            Solicite uma visita ou demonstração
            <img class="icon-arrow" src="@/assets/icon-arrow.png" alt="arrow"/>
          </a>
        </div>
      </v-col>

      <img class="waterfall" src="@/assets/waterfall.png" alt="waterfall"/>

      <!--<img class="dots hidden-sm-and-down" src="@/assets/dots.png" alt="dots"/>-->
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MainHome'
}
</script>

<style scoped>
#main-home {
  height: calc(100vh - 100px);
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.main-home-text {
  color: #E7E6FF;
  font-size: 35px;
  letter-spacing: 2px;
  font-weight: 200;
  line-height: 65px;
  z-index: 1;
  max-width: 789px;
}

.main-row {
  position: relative;
}

.main-home-text-blue {
  color: #41a4f3;
  font-weight: 400;
}

.main-home-text-link {
  color: #E7E6FF !important;
  display: flex;
  align-items: center;
  text-decoration-thickness: from-font;
  font-weight: 100;
  font-size: 25px;
}

.waterfall {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 960px;
  opacity: 0.7;
}

.dots {
  position: absolute;
  right: 0;
  bottom: 50px;
  height: 90px;
  width: 25px;
  z-index: 1;
}

.icon-arrow {
  margin-left: 5px;
  height: 34px;
  width: 34px;
}

@media screen and (max-width: 1600px) {
  .waterfall {
    width: 50%;
  }
}

@media screen and (max-width: 1800px) {
  .waterfall {
    width: 60%;
  }
}

@media screen and (max-width: 959px) {
  .main-home-text {
    order: 2;
    font-size: 18px;
    line-height: 35px;
  }

  .main-home-text-link {
    font-size: 14px;
  }

  .main-row {
    padding-left: 0;
    padding-right: 0;
  }

  .waterfall {
    order: 1;
    position: static;
    margin: auto;
    height: 40vh;
    min-width: unset;
    width: 100%;
    max-width: 400px;
  }

  .icon-arrow {
    height: 20px;
    width: 20px;
  }
}
</style>