<template>
  <div id="partners-home">
    <wave-component color="#100E3F"/>

    <h2 class="partners-title text-center pt-5 pb-10">Parceiros e Clientes</h2>

    <image-moving id="partners-img" image="services/partners.png"/>
  </div>
</template>

<script>
import WaveComponent from '@/components/WaveComponent.vue';
import ImageMoving from '@/components/ImageMoving.vue';

export default {
  name: 'PartnersHome',
  components: {ImageMoving, WaveComponent}
}
</script>

<style>
#partners-home {
  background-color: #0d0c37;
}

.partners-title {
  font-family: "Montserrat", sans-serif;
  font-size: 40px;
  color: #E7E6FF;
  letter-spacing: 2px;
}

#partners-img {
  height: 100vh;
}

@media screen and (max-width: 959px) {
  .partners-title {
    font-size: 26px;
  }
}
</style>
