<template>
  <div id="top-bar" class="main-container">
    <v-avatar tile size="80">
      <v-img src="@/assets/logo.png" contain/>
    </v-avatar>
    <v-spacer/>
    <div id="top-menu" class="hidden-sm-and-down">
      <v-btn class="nav-link nav-link-left" href="#" dark text tile>HOME</v-btn>
      <v-btn class="nav-link" href="#services-home" dark text tile>SERVIÇOS</v-btn>
      <v-btn class="nav-link" href="#differentials-home" dark text tile>SOBRE</v-btn>
      <v-btn class="nav-link nav-link-right" href="#partners-home" dark text tile>PARCEIROS</v-btn>
    </div>
    <v-spacer/>
    <v-btn id="top-contact-button" class="hidden-sm-and-down" href="#" target="_blank" color="#41a4f3" outlined rounded>
      Contato
    </v-btn>
    <v-btn class="hidden-md-and-up" href="#" width="80" height="80" icon dark>
      <v-icon id="menu-icon">mdi-menu</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'SiteHeader',
}
</script>


<style scoped>
#top-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  z-index: 3;
}

#top-menu {
  display: flex;
  background-color: #101547;
  border: #41a4f3 1px solid;
  border-radius: 28px;
  font-size: 12px;
}

#top-contact-button {
  padding-left: 30px;
  padding-right: 30px;
  border: 2px solid;
  font-weight: bolder;
}

#menu-icon {
  font-size: 60px;
}

.nav-link {
  padding: 22px 30px !important;
  font-weight: 100 !important;
}

.nav-link-left {
  border-bottom-left-radius: 28px;
  border-top-left-radius: 28px;
}

.nav-link-right {
  border-bottom-right-radius: 28px;
  border-top-right-radius: 28px;
}
</style>